import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class MutasiStockReportService {
    readMutasiStockReportQuery(){
        var query = `
            query ($startDate: String!, $endDate: String!, $storage: String, $item: String, $typeDetail: String) {
                GetReportingStockMutation(
                        start_date: $startDate, 
                        end_date: $endDate, 
                        storage_id: $storage, 
                        item_id: $item, 
                        type_detail_id: $typeDetail) 
                {
                    created_at
                    last_update
                    mut_id
                    storage_id
                    mut_reason
                    status
                    created_by
                    item_id
                    item_name
                    product_code
                    type_detail_id
                    uom
                    base_uom
                    qty
                    qty_change
                    conv_amount
                    stock_in
                    stock_out
                    base_stock_in
                    base_stock_out
                    ref_number
                    ref_date
                    type_detail_name
                    group_type_name
                    item_group_name
                    stock_after_change
                    current_stock
                } 
            }`;
        return query;
    }

    async getTypeDetailQuery(){
        var query = gql`query{
            GetTypeDetail(Flags:["All","Project","Retail"]) {
                type_detail_id
                type_detail_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetTypeDetail;
        var typeDetailData = [];
        if(data != null){
            for (let i = 0; i < data.length; i++) {
                var str = { 
                    value: data[i].type_detail_id, 
                    label: data[i].type_detail_id + ' (' + data[i].type_detail_name + ')'
                }
                typeDetailData.push(str);
            }
        }
        return typeDetailData
    }

    async getStorageQuery(){
        var query = gql`query{
            GetStorage{
                storage_id
                storage_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetStorage;
        var storageData = [];
        for (let i = 0; i < data.length; i++) {
            var str = { value: data[i].storage_id, 
                        label: data[i].storage_name}
            storageData.push(str);
        }

        return storageData;
    }

    async getItemQuery(){
        var query = gql`query{
            GetProductMaster{
                ProductMaster{
                    item_id
                    product_code
                    item_name
                }
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetProductMaster.ProductMaster;
        var itemData = [];
        for (let i = 0; i < data.length; i++) {
            var str = { value: data[i].item_id, 
                label: data[i].product_code + ' (' + data[i].item_name + ')'}
            itemData.push(str);
        }

        return itemData;
    }

    mutasiStockReportExcelHeaderSize(){
        var size = [
            {width: 60}, //colA
            {width: 20}, //colB
            {width: 20}, //colC
            {width: 20}, //colD
            {width: 20}, //colE
            {width: 20}, //colF
            {width: 20}, //colG
            {width: 20}, //colH
            {width: 20}, //colI
            {width: 20}, //colJ
            {width: 20}, //colK
            {width: 20}, //colL
            {width: 20}, //colM
            {width: 20}, //colN
        ];

        return size;
    }

    mutasiStockReportExcel(data, variables){
        const uniques = [];
        data.forEach((t) => !uniques.includes(t.product_code) && uniques.push({product_code : t.product_code, current_stock : t.current_stock, item_name : t.item_name}));

        var arrayObject = [];
        var date = 'Per ' + moment(variables.endDate).format('DD MMM YYYY');
        var numberFormat = '_(* #,##0_);_(* (#,##0);_(* "-"_);_(@_)';

        var startIndex = 5;
        var lastIndex = startIndex;

        if(data != null){
            lastIndex = lastIndex + data.length - 1;
        }

        //Header
        var row1 = {colA : 'PT BOJONG WESTPLAS'};
        arrayObject.push(row1);

        var row2 = {colA : 'Mutasi Stock'};
        arrayObject.push(row2);

        var row3 = {colA : date};
        arrayObject.push(row3);

        var spaceRow = {colA : null};
        arrayObject.push(spaceRow);

        if(data != null){
            for (let k= 0; k < uniques.length; k++) {     
                var headerRow1 = {
                    colA : "Kode Item : " + uniques[k].product_code + " (" + uniques[k].item_name + ")",
                    colB : "Current Stock : " + uniques[k].current_stock,
                };
                arrayObject.push(headerRow1);

                var headerRow2 = {
                    colA : 'Item Group',
                    colB : 'Group Type',
                    colC : 'Tipe Detail',
                    colD : 'Gudang',
                    colE : 'Nomor Transaksi',
                    colF : 'Tanggal Transaksi',
                    colG : 'Satuan (Konversi)',
                    colH : 'Stock Masuk (Konversi)',
                    colI : 'Stock Keluar (Konversi)',
                    colJ : 'Satuan (Dasar)',
                    colK : 'Stock Masuk (Dasar)',
                    colL : 'Stock Keluar (Dasar)',
                    colM : 'Stock (Dasar)',
                    colN : 'Alasan Mutasi',
                };
                arrayObject.push(headerRow2);

                var detail = data.filter(c => c.product_code == uniques[k].product_code);
                for (let i = 0; i < detail.length; i++){
                    var row = {
                        colA : detail[i].item_group_name,
                        colB : detail[i].group_type_name,
                        colC : detail[i].type_detail_name,
                        colD : detail[i].storage_id,
                        colE : detail[i].ref_number,
                        colF : detail[i].ref_date != '' ? { v: detail[i].ref_date, t: 'd', z: 'DD-MMM-YYYY' } : '-',
                        colG : detail[i].uom,
                        colH : detail[i].stock_in,
                        colI : detail[i].stock_out,
                        colJ : detail[i].base_uom,
                        colK : detail[i].base_stock_in,
                        colL : detail[i].base_stock_out,
                        colM : detail[i].stock_after_change == null ? 0 : detail[i].stock_after_change,
                        colN : detail[i].mut_reason,
                    };
                    arrayObject.push(row);
                }
                        
                var spaceRow = {colA : null};
                arrayObject.push(spaceRow);
            }
        }

        return arrayObject;
    }
}


export default new MutasiStockReportService();